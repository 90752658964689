document.addEventListener( 'DOMContentLoaded', () => {
  const observer = new MutationObserver( () => {
    const listItems = document.querySelectorAll( '.whr-item' );

    if ( listItems.length > 0 ) {
      observer.disconnect();

      listItems.forEach( li => {
        const anchor = li.querySelector( '.whr-title a' );
        
        if ( anchor ) {
          const innerAnchor = li.querySelector( '.whr-title a' );
          if ( innerAnchor ) {
            const textContent = innerAnchor.textContent;
            innerAnchor.replaceWith( textContent );
          }

          const liContent = li.innerHTML;
          
          anchor.innerHTML = liContent;

          li.innerHTML = '';
          li.appendChild( anchor );
        }
      } );
    }
  } );

  observer.observe( document.body, { childList: true, subtree: true } );
} );
